import useProcedureId from "../useProcedureId";
import { useQueryPlcs } from "../queryHooks/methodologyQueries/dynamicsQueries";
import { createFieldArray, numberType, textType } from "../../FormConfigs/utils";
import React from "react";
import _ from "lodash";

const prepare = (data) => {
    const preparedData = _.cloneDeep(data)
    _.set(preparedData, 'plc_configuration.sensor_chain_lengths', (data?.plc_configuration?.sensor_chain_lengths || []).map(({count}) => count))
    _.set(preparedData, 'plc_configuration.sensor_count', (preparedData?.plc_configuration?.sensor_chain_lengths || []).reduce((acc, cur) => acc + cur, 0))
    return preparedData
}

export function usePostDynamicsProcedurePlcConfigurationFields() {
    const {procedureId} = useProcedureId()
    const {data: plcs} = useQueryPlcs()
    const [plcIds, plcLabels] = React.useMemo(() => [
        (plcs || []).map(plc => plc.id),
        Object.fromEntries((plcs || []).map(plc => [plc.id, plc.name]))
    ], [plcs])
    
    const formFields = React.useMemo(() => ({
        procedure_id: {_type: 'hidden', defaultValue: procedureId, label: 'procedure'},
        plc_id: {
            ...textType,
            label: 'plc',
            __meta: {
                autoCompleteValues: plcIds,
                valueLabels: plcLabels,
                freeSolo: false,
                required: true
            }
        },
        
        plc_configuration: {
            _type: 'group',
            sampling_frequency_hertz: {
                ...numberType,
                label: 'sampling_frequency_hertz',
                min: 1,
                max: 200,
                step: 1,
                __meta: {required: true}
            },
            keepalive_seconds: {
                ...numberType,
                label: 'keepalive_seconds',
                min: 10,
                step: 1,
                __meta: {required: true}
            },
            sensor_chain_lengths: { ...createFieldArray({count: {
                ...numberType,
                min: 1,
                max: 128,
                step: 1,
                __meta: {required: true}
            }})}
            
        }
        
    }), [procedureId, plcIds, plcLabels])
    

    return {formFields, prepare}
}