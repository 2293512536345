import React from 'react'
import { Paper, List, ListItem, ListItemText, Collapse, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import useProcedureId from '../../../customHooks/useProcedureId'
import useMethodology from "../../../customHooks/useMethodology";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        height: 'calc(100vh - 64px)',
        background: theme.palette.primary.main,
        width: 240,
        overflow: 'hidden'
    },
    list: {
        background: theme.palette.primary.main,
    },
    levelBtn: {
        // textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
    },
    // material-ui override
    root: {
        color: theme.palette.primary.contrastText,
        '&$selected': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                background: theme.palette.secondary.dark,
            },
        },
        '&:hover': {},
    },
    selected: {},
}))

const accordionItemsDescriptionsByMethodology = Object.freeze({
    dynamics: [
        {
            title: 'dynamics_title',
            name: 'overview',
            submenus: [
                {title: 'dynamics_index', name: 'index'},
                {title: 'modal_analyses', name: 'modale'},
                {title: 'thresholds_analyses', name: 'soglie'}
            ]
        },
        {
            title: 'dynamics_configuration',
            name: 'configure',
            submenus: [
                {title: 'admin.plc', name: 'plcs'},
                {title: 'analysis_analysis', name: 'analyses'}
            ]
        }
    ],
    
    resisto: [
    
    ]
}
)
const useAccordionItems = () => {
    const history = useHistory()
    const currentUrl = useLocation().pathname
    const { procedureId } = useProcedureId();
    const { methodology } = useMethodology()
    const accordionItemDescriptions = methodology && accordionItemsDescriptionsByMethodology[methodology]
    const [selectedItemName, setSelectedItemName] = React.useState(accordionItemDescriptions.find(item => currentUrl.startsWith(`/procedures/${procedureId}/dynamics/${item.name}`))?.name)
    const accordionItems = React.useMemo(() => {
        return accordionItemDescriptions.map((item) => ({
            ...item,
            path: `/procedures/${procedureId}/dynamics/${item.name}`,
            isOpen: selectedItemName === item.name,
            onClick: (item.submenus.length) ?
                () => setSelectedItemName(selectedItemName === item.name ? undefined : item.name)
                : () => history.push(`/procedures/${procedureId}/dynamics/${item.name}`),
            submenus: item.submenus.map((submenuItem) => ({
                ...submenuItem,
                path: `/procedures/${procedureId}/dynamics/${item.name}/${submenuItem.name}`,
                onClick: () => {
                    history.push(
                        `/procedures/${procedureId}/dynamics/${item.name}/${submenuItem.name}`
                    )
                },
            })),
        }))
    }, [procedureId, history, selectedItemName, setSelectedItemName, accordionItemDescriptions])
    
    return {accordionItems}
    
}


export default function DynamicsToolbar() {
    const classes = useStyles()
    const { t } = useTranslation()
    const currentUrl = useLocation().pathname
    
    const {accordionItems} = useAccordionItems()
    
    return (
        <Paper className={classes.toolbar} square elevation={3}>
            <List className={classes.list}>
                {accordionItems.map((item) => {
                    return (
                        <React.Fragment key={item.path}>
                            <ListItem
                                button
                                classes={{
                                    root: classes.root,
                                    selected: classes.selected,
                                }}
                                onClick={item.onClick}
                                selected={_.includes(currentUrl, item.path) && (!item?.submenus?.length || !item.isOpen)}
                            >
                                <ListItemText
                                    primary={t(item.title)}
                                    primaryTypographyProps={{
                                        className: classes.levelBtn,
                                    }}
                                />
                                {item.isOpen ? (
                                    <ExpandLess style={{ position: 'absolute' }} />
                                ) : (
                                    <ExpandMore style={{ position: 'absolute' }} />
                                )}
                            </ListItem>
                            <Divider light variant="middle" />
                            <Collapse in={item.isOpen} timeout="auto">
                                <List component="div" disablePadding>
                                    {item.submenus.map(submenu => (
                                        <ListItem
                                            key={submenu.path}
                                            button
                                            selected={_.includes(currentUrl, submenu.path)}
                                            onClick={submenu.onClick}
                                            classes={{
                                                root: classes.root,
                                                selected: classes.selected,
                                            }}
                                        >
                                            <ListItemText
                                                style={{ textAlign: 'center' }}
                                                primary={_.capitalize(t(submenu.title))}
                                            />
                                        </ListItem>
                                        
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                })}
            </List>
        </Paper>
    )
}
