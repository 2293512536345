import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useAccordionStyles } from "../customHooks/styles/accordion";

export default function AppAccordion({
    children,
    accordionLabel,
    avatarContent,
    renderHeaderContent,
    avatarStyle = {},
    accordionDetailsStyle,
    ...props
}) {
    const classes = useAccordionStyles()
    const { t } = useTranslation()

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: t(accordionLabel),
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {renderHeaderContent && renderHeaderContent()}
                    {avatarContent && (
                        <Avatar className={classes.avatar} style={avatarStyle}>
                            {avatarContent}
                        </Avatar>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails style={accordionDetailsStyle}>{children}</AccordionDetails>
        </Accordion>
    )
}
