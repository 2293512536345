import { makeStyles } from "@material-ui/core/styles";

export const useAccordionStyles = makeStyles((theme) => ({
    avatar: {
        width: 24,
        height: 24,
        fontSize: '0.85rem',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginLeft: 10,
    },
    summary: {
        justifyContent: 'space-around',
    },
    customChip: {
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
        marginRight: '10px',
        borderRadius: '10px',
        padding: '0 8px',
        display: 'flex',
        justifyContent: 'center',
    },
    customChipMobile: {
        fontSize: '11px',
        height: '16px',
        borderRadius: '8px',
        padding: '0 4px',
    },
}))
