import useProcedureId from "../../useProcedureId";
import { usePlcId } from "../../createUseParam";
import { useMutation } from "react-query";
import axios from "axios";
import { queryClient } from "../../../networking";

export function usePostProcedurePlcConfiguration() {
    const {procedureId} = useProcedureId();
    const {plcId} = usePlcId();
    return useMutation(
        async (data) => {
            const resp = await axios.post('/dynamics/configurations', {procedure_id: procedureId, plc_id: plcId, ...data})
            return resp.data
        },
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['getProcedurePlcConfigurations'])
                return data
            }
        }
    )
}

export function usePutProcedurePlcConfiguration() {
    return useMutation(
        async ({procedurePlcConfigurationId, update}) => {
            const resp = await axios.put(`/dynamics/configurations?procedure_plc_configuration_id=${procedurePlcConfigurationId}`, update)
            return resp.data
        },
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['getProcedurePlcConfigurations'])
                return data
            }
        }
    )
}

export function usePostAnalysis() {
    return useMutation(
        async (data) => {
            const resp = await axios.post('/dynamics/analyses', data)
            return resp.data
        },
        {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['getAnalyses'])
                return data
            }
        }
    )
}