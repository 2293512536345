import { useQueryPlcs, useQueryProcedurePlcConfigurations } from "../../customHooks/queryHooks/methodologyQueries/dynamicsQueries";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Grid, Tooltip } from "@material-ui/core";
import { usePutProcedurePlcConfiguration } from "../../customHooks/mutationHooks/methodologyMutations/dynamicsMutations";
import { useTranslation } from "react-i18next";
import PostProcedurePlcConfigurationModal from "./PostProcedurePlcConfigurationModal";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AppChip } from "../../components/QuickFilterChip";
import PostAnalysisModal from "./PostAnalysisModal";
import { useProcedurePlcConfigurationId } from "../../customHooks/createUseParam";
import { useAccordionStyles } from "../../customHooks/styles/accordion";
import { useLargeTooltipStyles } from "../../customHooks/styles/tooltip";

function DynamicsConfigurePlc() {
    const {t} = useTranslation()
    const {data: procedurePlcConfigurations } = useQueryProcedurePlcConfigurations()
    const [isPostProcedurePlcConfigurationModalOpen, setIsPostProcedurePlcConfigurationModalOpen] = React.useState(false)
    
    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <Button variant="contained" color="secondary" onClick={() => setIsPostProcedurePlcConfigurationModalOpen(true)}>
                    {t('create_procedure_plc_configuration')}
                </Button>
            </Grid>
            <Grid item xs>
                <Grid contaier spacing={2}>
                    <Grid item xs>
                        {!!procedurePlcConfigurations?.length && (
                            procedurePlcConfigurations.map((procedurePlcConfiguration) => (
                                <ProcedurePlcConfigurationAccordionItem key={procedurePlcConfiguration.id} procedurePlcConfiguration={procedurePlcConfiguration} />
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
            
            
            <PostProcedurePlcConfigurationModal open={isPostProcedurePlcConfigurationModalOpen} onClose={() => setIsPostProcedurePlcConfigurationModalOpen(false)} />
            <PostAnalysisModal />
        </Grid>
    )
}

export default DynamicsConfigurePlc;

function ProcedurePlcConfigurationAccordionItem({procedurePlcConfiguration}) {
    const classes = useAccordionStyles()
    const {t} = useTranslation()
    const {mutateAsync: updateProcedurePlcConfiguration} = usePutProcedurePlcConfiguration()
    
    const {setProcedurePlcConfigurationId} = useProcedurePlcConfigurationId()
    const openPostAnalysisModal = (procedurePlcConfiguration) => setProcedurePlcConfigurationId(procedurePlcConfiguration?.id)
    
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <ProcedurePlcConfigurationSummaryHeader procedurePlcConfiguration={procedurePlcConfiguration} />
                    </Grid>
                    
                    <Grid item>
                        <AppChip
                            isActive={procedurePlcConfiguration.is_active}
                            label={procedurePlcConfiguration.is_active ? t('active') : t('inactive')}
                        />
                    </Grid>
                
                </Grid>
            </AccordionSummary>
            <AccordionDetails style={{overflow: 'scroll'}}>
                <pre>{JSON.stringify(procedurePlcConfiguration, null, 4)}</pre>
            </AccordionDetails>
            <AccordionActions>
                {procedurePlcConfiguration.is_active && (
                    <Button variant={'contained'} onClick={() => updateProcedurePlcConfiguration({procedurePlcConfigurationId: procedurePlcConfiguration.id, update: {is_active: false}})}>
                        {t('deactivate')}
                    </Button>
                )}
                {!procedurePlcConfiguration.is_active && (
                    <Button variant={'contained'} onClick={() => updateProcedurePlcConfiguration({procedurePlcConfigurationId: procedurePlcConfiguration.id, update: {is_active: true}})}>
                        {t('activate')}
                    </Button>
                )}
                <Button variant={'contained'} color={'secondary'} onClick={() => openPostAnalysisModal(procedurePlcConfiguration)}>
                    {t('create_analysis')}
                </Button>
                
            </AccordionActions>
            
        </Accordion>
    )
}

export function ProcedurePlcConfigurationSummaryHeader({ procedurePlcConfiguration }) {
    const { t } = useTranslation()
    const {tooltip} = useLargeTooltipStyles()
    const { data: plcs } = useQueryPlcs()
    const plc = React.useMemo(
        () => (plcs || []).find((plc) => plc.id === procedurePlcConfiguration.plc_id),
        [procedurePlcConfiguration.plc_id, plcs]
    )
    return (
        <Grid container spacing={1}>
            <Grid item>
                <Tooltip title={<pre>{JSON.stringify(plc, null, 4)}</pre>} classes={{ tooltip }}>
                    <span>{plc?.name}</span>
                </Tooltip>
            </Grid>
            <Grid item>{' \u2022 '}</Grid>
            <Grid item>
                <Tooltip title={<pre>{JSON.stringify({sensor_chain_lengths: procedurePlcConfiguration.plc_configuration.sensor_chain_lengths}, null, 4)}</pre>} classes={{ tooltip }}>
                    <span>{procedurePlcConfiguration.plc_configuration.sensor_count} {t('sensors')}</span>
                </Tooltip>
            </Grid>
            <Grid item>{' \u2022 '}</Grid>
            <Grid item>
                <span>{new Date(procedurePlcConfiguration.created_at).toLocaleDateString()}</span>
            </Grid>
        </Grid>
    )
    
}