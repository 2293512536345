import { makeStyles } from "@material-ui/core/styles";

export const useLargeTooltipStyles = makeStyles({
    tooltip: {
        maxWidth: 'none', // Removes the default width limit
        width: 'fit-content', // Ensures the tooltip width fits the content
        whiteSpace: 'pre-wrap', // Maintains formatting and wraps long lines
        fontSize: '0.8rem'
    },
});

