import React from "react";
import { createFieldArray, numberType, textareaType, textType } from "../../FormConfigs/utils";
import { useProcedurePlcConfigurationId } from "../createUseParam";
import { usePostAnalysis } from "../mutationHooks/methodologyMutations/dynamicsMutations";
import { useSnackBarStore } from "../../stateManagement";
import { useForm } from "react-hook-form";


export function usePostDynamicsAnalysisForm() {
    const {procedurePlcConfigurationId, setProcedurePlcConfigurationId} = useProcedurePlcConfigurationId()
    const {mutateAsync} = usePostAnalysis()
    const showSnackbar = useSnackBarStore(state => state.show)
    
    const onSubmit = (data) => {
        mutateAsync(data)
            .then(() => {
                showSnackbar({ message: 'analysis created', severity: 'success' })
                setProcedurePlcConfigurationId(undefined)
            })
            .catch((e) => showSnackbar({ message: e.message, severity: 'error' }))
    }
    
    const methods = useForm({
        mode: 'onBlur',
    })
    
    const formFields = React.useMemo(() => ({
        analysis_configuration: {
            _type: 'group',
            analysis_type: {
                ...textType,
                __meta: {
                    autoCompleteValues: ['soglie', 'modale'],
                    freeSolo: false,
                    required: true
                }
            },
            docker_image_url: {
                ...textType,
                __meta: {
                    autoCompleteValues: ['**replace_with_docker_image_1**', '**replace_with_docker_image_2**'],
                    freeSolo: true,
                    required: true
                }
            },
            input_files: createFieldArray({
                name: textType,
                content: textareaType,
            }),
            seconds_data_to_analyze: {
                ...numberType,
                label: 'window_width_seconds',
                min: 0,
                step: 1
            },
            
        },
        procedure_plc_configuration_id: {_type: 'hidden', defaultValue: procedurePlcConfigurationId}
    }), [procedurePlcConfigurationId])
    
    return { formFields, onSubmit, methods }
    
    
}