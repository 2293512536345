import { Route, Switch } from "react-router-dom";
import DynamicsOverview from "./DynamicsOverview";
import DynamicsAnalyses from "./DynamicsAnalyses";
import DynamicsResults from "./DynamicsResults";
import DynamicsConfigureInfrastructure from "./DynamicsConfigureInfrastructure";
import DynamicsConfigureAnalyses from "./DynamicsConfigureAnalyses";
import DynamicsConfigurePlc from "./DynamicsConfigurePlc";
import React from "react";
import MethodologyPage from "../MethodologyPage";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnalysisResults from "../../components/3sAnalysisResults/AnalysisResults";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        background: theme.palette.background.default,
    }
}))

function DynamicsProcedurePage({match}) {
    const classes = useStyles()
    return (
        <MethodologyPage>
            <Paper className={classes.paper} >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.overlay}
                >
                    <Switch>
                        <Route
                            path={`${match.path}/overview/index`}
                            component={DynamicsOverview}
                        />
                        <Route
                            exact
                            path={`${match.path}/overview/modale`}
                            component={DynamicsAnalyses}
                        />
                        <Route
                            exact
                            path={`${match.path}/overview/soglie`}
                            component={DynamicsAnalyses}
                        />
                        <Route
                            exact
                            path={`${match.path}/overview/:analysisId`}
                            component={AnalysisResults}
                        />
                        <Route
                            path={`${match.path}/configure/plcs`}
                            component={DynamicsConfigurePlc}
                        />
                        <Route
                            path={`${match.path}/configure/analyses`}
                            component={DynamicsConfigureAnalyses}
                        />
                    </Switch>
                </Grid>
            </Paper>
        </MethodologyPage>
    )
}

export default DynamicsProcedurePage;