import { usePostDynamicsProcedurePlcConfigurationFields } from "../../customHooks/formFields/usePostDynamicsProcedurePlcConfigurationFields";
import { usePostProcedurePlcConfiguration } from "../../customHooks/mutationHooks/methodologyMutations/dynamicsMutations";
import { useSnackBarStore } from "../../stateManagement";
import { useForm } from "react-hook-form";
import AppForm from "../../components/AppForm";
import DynamicFormFields from "../../components/FormItems/DynamicFormFields";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function PostProcedurePlcConfigurationModal({open, onClose}) {
    const {t} = useTranslation()
    const { formFields, prepare } = usePostDynamicsProcedurePlcConfigurationFields()
    const { mutateAsync} = usePostProcedurePlcConfiguration()
    const showSnackbar = useSnackBarStore(state => state.show)
    
    
    const methods = useForm({
        mode: 'onBlur',
    })
    const {isDirty, isValid} = methods.formState
    const onSubmit = (data) =>
        mutateAsync(prepare(data))
            .then(() => {
                showSnackbar({ message: 'configuration created', severity: 'success' })
                onClose()
            })
            .catch((e) => showSnackbar({ message: e.message, severity: 'error' }))
    return (
        <Dialog open={open} onClose={onClose} unmountOnExit={true} scroll="paper">
            <AppForm onSubmit={onSubmit} methods={methods}>
                <DialogTitle>
                    {t('create_procedure_plc_configuration')}
                </DialogTitle>
                <DialogContent>
                    <DynamicFormFields
                        fieldsKey={""}
                        formFields={formFields}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>
                        {t('close')}
                    </Button>
                    <Button type="submit" variant="contained" color="secondary" disabled={!(isDirty && isValid)}>
                        {t('create')}
                    </Button>
                </DialogActions>
            </AppForm>
        </Dialog>
    )
}