import React from "react";
import { Button } from "@material-ui/core"
import { Box, Divider, Typography, Grid, Link } from "@material-ui/core";
import moment from "moment";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ThresholdsCrossCharts from "./ThresholdsCrossCharts";
import ModalCrossCharts from "./ModalCrossCharts";

import MyDataGrid from "./UI/DataGrid";
import { useQueryProcedureAnalysisResults } from "../../customHooks/queryHooks/methodologyQueries/dynamicsQueries";
import useProcedureId from "../../customHooks/useProcedureId";
import useMethodology from "../../customHooks/useMethodology";
import { useDateRange } from "../DateRangePicker";

const StyledLink = Link

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

function formatDate(dateString) {
    return moment.utc(dateString).format("DD/MM/YYYY - HH:mm:ss");
}

const columns = [
    {
        field: "execution_date",
        headerName: "Data",
        flex: 1,
        valueGetter: (params) => formatDate(params.row.execution_date),
    },
    {
        field: "action",
        headerName: "Azioni",
        flex: 0.5,
        align: "right",
        headerAlign: "right",
        sortable: false,
        disableColumnMenu: true,
        renderCell: ButtonGoToDynamicsResult,
    },
];

function AnalysisResults() {
    const { data: allResults } = useQueryProcedureAnalysisResults()
    const { procedureId } = useProcedureId()
    const { methodology } = useMethodology()
    
    const dateRange = useDateRange()
    
    const filteredResults = React.useMemo(() => {
        return allResults
        .filter(result => !dateRange.start || dateRange.start <= result.execution_date)
        .filter(result => !dateRange.end || dateRange.end >= result.execution_date)
    }, [allResults, dateRange.start, dateRange.end])
    
    
    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: '100%' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1 }}>
                    <Typography variant="h4" fontWeight={700}>
                        Risultati Analisi
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={400}>
                        Cross Charts and Details
                    </Typography>
                </Box>
                
                <Divider variant="middle" sx={{ py: 2 }} />
                
                {/*{allResults?.length && (analysisType === 'modale') && (*/}
                {/*        <ModalCrossCharts filteredResoultFound={filteredResults} />*/}
                {/*    )}*/}
                {/*{allResults?.length && (analysisType === 'soglie') && (*/}
                {/*        <ThresholdsCrossCharts filteredResoultFound={filteredResults} />*/}
                {/*    )}*/}
                
                <Grid item display="flex" justifyContent="center" alignItems="center">
                    <Box
                        // width={"50%"}
                        height={400}
                    >
                        {/*<MyDataGrid*/}
                        {/*    columns={columns}*/}
                        {/*    rows={(analysisResults || []).filter((result) =>*/}
                        {/*        dateRange.start && dateRange.end*/}
                        {/*            ? dayjs(result.execution_date).isBetween(*/}
                        {/*                  dayjs(`${dateRange.start}`),*/}
                        {/*                  dayjs(`${dateRange.end}`),*/}
                        {/*                  null,*/}
                        {/*                  '[]'*/}
                        {/*              )*/}
                        {/*            : true*/}
                        {/*    )}*/}
                        {/*/>*/}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}



export default AnalysisResults;

function ButtonGoToDynamicsResult({resultId}) {
    const { procedureId } = useProcedureId()
    
    return (
        <Button
            component={StyledLink}
            to={`/procedures/${procedureId}/dynamics/${resultId}`}
            variant="outlined"
        >
            Dettagli
        </Button>
    )
};