import { useQueryPlcs, useQueryProcedureAnalyses, useQueryProcedurePlcConfigurations } from "../../customHooks/queryHooks/methodologyQueries/dynamicsQueries";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppChip } from "../../components/QuickFilterChip";
import { useLargeTooltipStyles } from "../../customHooks/styles/tooltip";
import { useAccordionStyles } from "../../customHooks/styles/accordion";

function DynamicsConfigureAnalyses() {
    const {t} = useTranslation()
    const {data: analyses} = useQueryProcedureAnalyses()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'h5'}>
                    {t('analisi_configurate')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {!!analyses?.length && (
                            analyses.map(analysis => (
                                <AnalysisAccordionItem key={analysis.id} analysis={analysis} />
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
            
            
            
 
        </Grid>
    );
}

export default DynamicsConfigureAnalyses;


function AnalysisAccordionItem({analysis}) {
    const classes = useAccordionStyles()
    const {tooltip} = useLargeTooltipStyles()
    const {t} = useTranslation()
    const {data: plcs} = useQueryPlcs()
    const {data: procedurePlcConfigurations} = useQueryProcedurePlcConfigurations()
    const plc = React.useMemo(() => (plcs || []).find(plc => plc.id === analysis.plc_id), [analysis.plc_id, plcs])
    const procedurePlcConfiguration = React.useMemo(() => (procedurePlcConfigurations || []).find(procedurePlcConfiguration => procedurePlcConfiguration.id === analysis.procedure_plc_configuration_id), [procedurePlcConfigurations, analysis.procedure_plc_configuration_id])
    const activeExecutionPoliciesCount = (analysis?.analysis_configuration?.execution_policies || []).filter(policy => policy.is_active).length
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Tooltip title={<pre>{JSON.stringify(plc, null, 4)}</pre>} classes={{ tooltip }}>
                                    <span>{plc?.name}</span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item>{' \u2022 '}</Grid>
                        
                        
                        
                        
                        {[plc?.name, new Date(analysis.created_at).toLocaleDateString()].join(
                            ' \u2022 '
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Tooltip
                                    classes={{ tooltip }}
                                    title={
                                        <pre>
                                            {JSON.stringify(procedurePlcConfiguration, null, 4)}
                                        </pre>
                                    }
                                >
                                    <span>
                                        <AppChip
                                            label={
                                                procedurePlcConfiguration?.is_active
                                                    ? t('active_configuration')
                                                    : t('inactive_configuration')
                                            }
                                            isActive={!!procedurePlcConfiguration?.is_active}
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <AppChip
                                    label={`${activeExecutionPoliciesCount} ${t(
                                        'active_policies'
                                    )}`}
                                    isActive={
                                        !!activeExecutionPoliciesCount &&
                                        procedurePlcConfiguration?.is_active
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: 'scroll' }}>
                <pre>{JSON.stringify(analysis, null, 4)}</pre>
            </AccordionDetails>
            <AccordionActions>
            
            </AccordionActions>
        </Accordion>
    )
}