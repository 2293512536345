import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateUtils from "@date-io/dayjs";
import { Grid } from "@material-ui/core";

export function useDateRange({defaultStart, defaultEnd} = {}) {
    const [start, setStart] = React.useState(defaultStart)
    const [end, setEnd] = React.useState(defaultEnd)
    return {start, setStart, end, setEnd}
}

const DateRangePicker = ({dateRange, labels = {}}) => {
    const {start, setStart, end, setEnd} = dateRange
    const {start: startLabel, end: endLabel} = labels
    return (
        <MuiPickersUtilsProvider utils={DateUtils}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DatePicker
                        label={startLabel}
                        value={start}
                        onChange={setStart}
                        // format="MM/dd/yyyy"
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        label={endLabel}
                        value={end}
                        onChange={setEnd}
                        // format="MM/dd/yyyy"
                        minDate={start}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default DateRangePicker;
