import AppForm from "../../components/AppForm";
import DynamicFormFields from "../../components/FormItems/DynamicFormFields";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { usePostDynamicsAnalysisForm } from "../../customHooks/formFields/usePostDynamicsAnalysisForm";
import { useProcedurePlcConfigurationId } from "../../customHooks/createUseParam";

export default function PostAnalysisModal() {
    const {t} = useTranslation()
    const {procedurePlcConfigurationId, setProcedurePlcConfigurationId} = useProcedurePlcConfigurationId()
    const { formFields, onSubmit, methods } = usePostDynamicsAnalysisForm()
    const {isDirty, isValid} = methods.formState
    return (
        <Dialog open={!!procedurePlcConfigurationId} onClose={() => setProcedurePlcConfigurationId(undefined)} unmountOnExit={true} scroll="paper">
            <AppForm onSubmit={onSubmit} methods={methods}>
                <DialogTitle>
                    {t('create_analysis')}
                </DialogTitle>
                <DialogContent>
                    <DynamicFormFields
                        fieldsKey={""}
                        formFields={formFields}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setProcedurePlcConfigurationId(undefined)}>
                        {t('close')}
                    </Button>
                    <Button type="submit" variant="contained" color="secondary" disabled={!(isDirty && isValid)}>
                        {t('create')}
                    </Button>
                </DialogActions>
            </AppForm>
        </Dialog>
    )
}