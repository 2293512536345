import { Grid, Typography } from "@material-ui/core";

function DynamicsOverview() {
    
    return <Grid container direction="column" spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
            <Typography>Analisi configurate</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Typography>PLC configurati</Typography>
        </Grid>
        
    </Grid>;
}

export default DynamicsOverview
