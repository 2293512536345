import {createOptions, createSelect} from "./utils";

export const sections_to_render_scheda_gndt_II_livello = {
    MASONRY: [
        'type_and_organization',
        'system_quality',
        'building_position',
        'horizontals',
        'layout_configuration',
        'elevation_configuration',
        'max_wall_distance',
        'covers',
        'non_structural_elements',
        'building_status',
    ],
    REINFORCED_CONCRETE: [
        'type_and_organization',
        'system_quality',
        'building_position',
        'horizontals',
        'layout_configuration',
        'elevation_configuration',
        'max_wall_distance',
        'covers',
        'non_structural_elements',
        'building_status',
    ],
    PREFABRICATED: [
        'type_and_organization',
        'system_quality',
        'building_position',
        'layout_configuration',
        'elevation_configuration',
        'covers',
        'building_status',
        'cladding_non_structural_elements',
        'internal_non_structural_elements',
        'node_and_critical_elements',
        'low_ductility_elements',
    ],
}

const getOptionsABC = () => ({
    _type: 'buttonGroup',
    buttons: [{ value: 'A' }, { value: 'B' }, { value: 'C' }],
    variant: 'columnInlineWide',
})
const getOptionsABCD = () => ({
    _type: 'buttonGroup',
    buttons: [{ value: 'A' }, { value: 'B' }, { value: 'C' }, { value: 'D' }],
    variant: 'columnInlineWide',
})

const default_options = [{ value: 'A' }, { value: 'B' }, { value: 'C' }]

export const resisto_scheda_gndt_II_livello = {
    type_and_organization: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    system_quality: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    building_position: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    horizontals: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    layout_configuration: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    elevation_configuration: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    max_wall_distance: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    covers: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    non_structural_elements: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    building_status: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    //CAMPI ESCLUSIVI PREFABRICATO
    cladding_non_structural_elements: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    internal_non_structural_elements: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    node_and_critical_elements: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
    low_ductility_elements: {
        _type: 'buttonGroup',
        buttons: default_options,
        variant: 'columnInlineWide',
    },
}

export const reinforcedConcreteGndtModel = {
    type_and_organization: getOptionsABC(),
    system_quality: getOptionsABC(),
    building_position: getOptionsABC(),
    horizontals: getOptionsABC(),
    layout_configuration: getOptionsABC(),
    elevation_configuration: getOptionsABC(),
    max_wall_distance: { ...getOptionsABC(), label: 'collegamenti ad elementi critici' },  // collegamenti ad elementi critici
    covers: { ...getOptionsABC(), label: 'Elementi con bassa duttilità' },  // elementi con bassa duttilita
    non_structural_elements: getOptionsABC(),
    building_status: getOptionsABCD(),
}

export const prefabricatedGndtModel = {
    type_and_organization: getOptionsABC(),
    system_quality: getOptionsABC(),
    building_position: getOptionsABC(),
    layout_configuration: getOptionsABC(),
    elevation_configuration: getOptionsABC(),
    covers: getOptionsABC(),
    building_status: getOptionsABC(),
    cladding_non_structural_elements: getOptionsABC(),
    internal_non_structural_elements: getOptionsABC(),
    node_and_critical_elements: getOptionsABC(),
    low_ductility_elements: getOptionsABC(),
}

export const masonryGndtModel = {
    type_and_organization: getOptionsABCD(),
    system_quality: getOptionsABCD(),
    building_position: getOptionsABCD(),
    horizontals: getOptionsABCD(),
    layout_configuration: getOptionsABCD(),
    elevation_configuration: getOptionsABCD(),
    max_wall_distance: getOptionsABCD(),
    covers: getOptionsABCD(),
    non_structural_elements: getOptionsABCD(),
    building_status: getOptionsABCD(),
}